import React from 'react';
import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';
import './tailwind.css';

const App = () => (
  <div className="font-sans antialiased bg-gray-100 flex flex-col min-h-screen">
    <Header />
    <Main />
    <Footer />
  </div>
);

export default App;
