import React from 'react';

const Footer = () => (
  <footer className="bg-white shadow-md py-4">
    <div className="container mx-auto px-4 text-center">
      <p className="text-sm text-gray-600">&copy; 2024 <a href="/">Start at Root</a></p>
    </div>
  </footer>
);

export default Footer;
