import React, { useState } from 'react';
import Modal from './Modal';

const Main = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
    if (!window.grecaptcha) {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js?render=6LdXYx8qAAAAACrJKpmOth3jLf1PaIcqOtb3FyIB';
      document.body.appendChild(script);
    }
  };

  return (
    <main className="flex-grow flex items-center justify-center bg-cover bg-center" style={{ backgroundImage: 'url(/assets/blue-opaque-background.jpg)' }}>
      <div className="text-center w-full max-w-md mx-auto">
        <h2 className="text-4xl font-bold text-white mb-8">STEAM Education Consultation & Supply</h2>
        <button onClick={openModal} className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Contact Us
        </button>
      </div>
      {modalOpen && <Modal setModalOpen={setModalOpen} />}
    </main>
  );
};

export default Main;
