import React, { useState } from 'react';

const Modal = ({ setModalOpen }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    description: ''
  });

  const [message, setMessage] = useState('');
  const [header, setHeader] = useState('Contact Us');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (window.grecaptcha) {
      const token = await window.grecaptcha.execute('6LdXYx8qAAAAACrJKpmOth3jLf1PaIcqOtb3FyIB', { action: 'submit' });
      const data = { ...formData, 'g-recaptcha-response': token };

      try {
        const response = await fetch('/submit-form', {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          setHeader('Thank You!');
          setMessage('We have received your message and will get back to you as soon as possible.');
          setFormData({
            firstName: '',
            lastName: '',
            email: '',
            subject: '',
            description: ''
          });
        } else {
          setHeader('Uh Oh...');
          setMessage('There was an error while processing your submission. Please review and try again.');
        }
      } catch (error) {
        console.error('Error:', error);
        setHeader('Uh Oh...');
        setMessage('There was an error while processing your submission. Please review and try again.');
      }
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center modal">
      <div className="bg-white p-6 rounded-lg shadow-md max-w-sm w-full relative">
        <button onClick={() => setModalOpen(false)} className="absolute top-0 right-0 mt-4 mr-4 text-gray-500 hover:text-gray-700">&times;</button>
        <h3 className="text-xl font-bold mb-4 text-center">{header}</h3>
        {message ? (
          <div id="modal-message" className="mb-4 text-center">{message}</div>
        ) : (
          <form id="contact-form" onSubmit={handleSubmit}>
            <div className="md:flex md:space-x-4 mb-4">
              <div className="mb-4 md:mb-0 w-full">
                <label htmlFor="first-name" className="block text-gray-700 text-sm font-bold mb-1">First Name</label>
                <input type="text" id="first-name" name="firstName" value={formData.firstName} onChange={handleChange} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
              </div>
              <div className="w-full">
                <label htmlFor="last-name" className="block text-gray-700 text-sm font-bold mb-1">Last Name</label>
                <input type="text" id="last-name" name="lastName" value={formData.lastName} onChange={handleChange} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-1">Email</label>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            </div>
            <div className="mb-4">
              <label htmlFor="subject" className="block text-gray-700 text-sm font-bold mb-1">Subject</label>
              <input type="text" id="subject" name="subject" value={formData.subject} onChange={handleChange} required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
            </div>
            <div className="mb-4">
              <label htmlFor="description" className="block text-gray-700 text-sm font-bold mb-1">Description</label>
              <textarea id="description" name="description" value={formData.description} onChange={handleChange} rows="3" required className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"></textarea>
            </div>
            <div className="flex items-center justify-between">
              <button type="submit" className="bg-blue-600 hover:bg-blue-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Submit</button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Modal;
