import React from 'react';

const Header = () => (
  <header className="bg-white shadow-md">
    <div className="container mx-auto px-4 py-4 flex justify-between items-center">
      <div className="header-content">
        <img src="/assets/cropped-Facebook-profile-picture-01-180x180.jpg" alt="Logo" className="logo" />
        <div>
          <h1 className="text-3xl font-bold"><a href="/">Start At Root</a></h1>
          <p className="text-sm text-gray-600">STEAM Education Consultation & Supply</p>
        </div>
      </div>
    </div>
  </header>
);

export default Header;
